import {checkSubDomain} from "../common";

const { REACT_APP_API_SERVER_URL } = process.env;

// const IS_PROD = NODE_ENV === 'production';
let SERVER_URL = REACT_APP_API_SERVER_URL || "";
SERVER_URL = checkSubDomain(SERVER_URL)

export const API_ENDPOINT = `${SERVER_URL}/api`;

// user auth endpoints
export const LOGIN_ENDPOINT = `${API_ENDPOINT}/login`;
export const REGISTRATION_ENDPOINT = `${API_ENDPOINT}/register`;
// export const LOGOUT_ENDPOINT = `${API_ENDPOINT}/logout`;
export const LOGOUT_ENDPOINT = `/modules/logout`;
export const PING_ENDPOINT = `/modules/ping`;
export const OTP_ENDPOINT = `/modules/otp`;
export const OTP_ENDPOINT_RESEND = `/modules/otp/resend`;

// user allowed modules endpoints
export const MODULES_ENDPOINT = `${API_ENDPOINT}/modules/domain-access`;

// user profile data
export const USER_DATA_ENDPOINT = `${API_ENDPOINT}/user`;

// widget endpoints
export const CAMPAIGN_ENDPOINT = `${API_ENDPOINT}/modules/social-sharing`;

// webpush
export const WEB_PUSH_ENDPOINT = `/modules/web-push`;
export const WEB_PUSH_STATUS_ENDPOINT = `${WEB_PUSH_ENDPOINT}/status`;
export const WEB_PUSH_FETCH_CAMPAIGN_NAMES_ENDPOINT = `${WEB_PUSH_ENDPOINT}/get-campaign-names`;
export const WEB_PUSH_SEND_PROMOCODES_FILE_ENDPOINT = `${WEB_PUSH_ENDPOINT}/promocodes`;
export const WEB_PUSH_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${WEB_PUSH_ENDPOINT}/validate-name`;
export const WEB_PUSH_CALCULATE_VISITORS_ENDPOINT = `${WEB_PUSH_ENDPOINT}/visitors`;

// email
export const EMAIL_ENDPOINT = `/modules/email`;
export const EMAIL_STATUS_ENDPOINT = `${EMAIL_ENDPOINT}/status`;
export const EMAIL_FETCH_CAMPAIGN_NAMES_ENDPOINT = `${EMAIL_ENDPOINT}/get-campaign-names`;
export const EMAIL_SEND_PROMOCODES_FILE_ENDPOINT = `${EMAIL_ENDPOINT}/promocodes`;
export const EMAIL_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${EMAIL_ENDPOINT}/validate-name`;
export const EMAIL_CALCULATE_VISITORS_ENDPOINT = `${EMAIL_ENDPOINT}/visitors`;
export const EMAIL_INFO_ENDPOINT = `${EMAIL_ENDPOINT}/info`;
export const EMAIL_TEMPLATE_ENDPOINT = '/modules/email-template';


// campaignOffer
export const CAMPAIGN_OFFER_ENDPOINT = `/modules/campaign/offer`;
export const CAMPAIGN_OFFER_STATUS_ENDPOINT = `${CAMPAIGN_OFFER_ENDPOINT}/status`;
export const CAMPAIGN_OFFER_SEND_PROMOCODES_FILE_ENDPOINT = `${CAMPAIGN_OFFER_ENDPOINT}/promocodes`;
export const CAMPAIGN_OFFER_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${CAMPAIGN_OFFER_ENDPOINT}/validate-name`;
export const CAMPAIGN_OFFER_CALCULATE_VISITORS_ENDPOINT = `${CAMPAIGN_OFFER_ENDPOINT}/visitors`;

// sms
export const SMS_ENDPOINT = '/modules/sms';
export const SMS_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${SMS_ENDPOINT}/validate-name`;
export const SMS_FETCH_CAMPAIGN_NAMES_ENDPOINT = `${SMS_ENDPOINT}/get-campaign-names`;
export const SMS_STATUS_ENDPOINT = `${SMS_ENDPOINT}/status`;
export const SMS_CALCULATE_VISITORS_ENDPOINT = `${SMS_ENDPOINT}/visitors`;

// promo codes
export const PROMO_CODE_UPLOADS_ENDPOINT = `/modules/promo-code-uploads`;
export const PROMO_CODE_UPLOADS_STATUS_ENDPOINT = `${PROMO_CODE_UPLOADS_ENDPOINT}/status`;
export const PROMO_CODE_UPLOADS_VERIFY_NAME_ENDPOINT = `${PROMO_CODE_UPLOADS_ENDPOINT}/validate-name`;
export const PROMO_CODE_UPLOADS_FILE_REPORT = `${PROMO_CODE_UPLOADS_ENDPOINT}/info`;

// sim numbers
export const SIM_NUMBER_UPLOADS_ENDPOINT = `/modules/sim-number-uploads`;
export const SIM_NUMBER_UPLOADS_STATUS_ENDPOINT = `${SIM_NUMBER_UPLOADS_ENDPOINT}/status`;
export const SIM_NUMBER_UPLOADS_VERIFY_NAME_ENDPOINT = `${SIM_NUMBER_UPLOADS_ENDPOINT}/validate-name`;
export const SIM_NUMBER_UPLOADS_FILE_REPORT = `${SIM_NUMBER_UPLOADS_ENDPOINT}/info`;

// sim sales points
export const SIM_SALES_POINT_UPLOADS_ENDPOINT = `/modules/sim-sales-point-uploads`;
export const SIM_SALES_POINT_UPLOADS_STATUS_ENDPOINT = `${SIM_SALES_POINT_UPLOADS_ENDPOINT}/status`;
export const SIM_SALES_POINT_UPLOADS_VERIFY_NAME_ENDPOINT = `${SIM_SALES_POINT_UPLOADS_ENDPOINT}/validate-name`;
export const SIM_SALES_POINT_UPLOADS_FILE_REPORT = `${SIM_SALES_POINT_UPLOADS_ENDPOINT}/info`;

// services
export const SERVICE_UPLOADS_ENDPOINT = `/modules/service-uploads`;
export const SERVICE_UPLOADS_STATUS_ENDPOINT = `${SERVICE_UPLOADS_ENDPOINT}/status`;
export const SERVICE_UPLOADS_VERIFY_NAME_ENDPOINT = `${SERVICE_UPLOADS_ENDPOINT}/validate-name`;
export const SERVICE_UPLOADS_FILE_REPORT = `${SERVICE_UPLOADS_ENDPOINT}/info`;

// sms-tree
export const SMS_TREE_ENDPOINT = '/modules/sms-tree';
export const SMS_TREE_STATUS_ENDPOINT = `${SMS_TREE_ENDPOINT}/status`;
export const SMS_TREE_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${SMS_TREE_ENDPOINT}/validate-name`;

// chat-bot
export const CHAT_BOT_ENDPOINT = '/modules/chatbot';
export const CHAT_BOT_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${CHAT_BOT_ENDPOINT}/validate-name`;
export const CHAT_BOT_STATUS_ENDPOINT = `${CHAT_BOT_ENDPOINT}/status`;
export const CHAT_BOT_INFO_ENDPOINT = `${CHAT_BOT_ENDPOINT}/info`;

// onsite
export const ONSITE_ENDPOINT = `/modules/onsite`;
export const ONSITE_STATUS_ENDPOINT = `${ONSITE_ENDPOINT}/status`;
export const ONSITE_INFO_ENDPOINT = `${ONSITE_ENDPOINT}/info`;
export const ONSITE_FETCH_CAMPAIGN_NAMES_ENDPOINT = `${ONSITE_ENDPOINT}/get-campaign-names`;
export const ONSITE_SEND_PROMOCODES_FILE_ENDPOINT = `${ONSITE_ENDPOINT}/promocodes`;
export const ONSITE_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${ONSITE_ENDPOINT}/validate-name`;
export const ONSITE_CALCULATE_VISITORS_ENDPOINT = `${ONSITE_ENDPOINT}/visitors`;
export const ONSITE_WIDGET_TEMPLATE_ENDPOINT = `${ONSITE_ENDPOINT}/widget/template`;

// social sharing
export const SOCIAL_SHARING_ENDPOINT = `/modules/social-sharing`;
export const SOCIAL_SHARING_STATUS_ENDPOINT = `${SOCIAL_SHARING_ENDPOINT}/status`;
export const SOCIAL_SHARING_FETCH_CAMPAIGN_NAMES_ENDPOINT = `${SOCIAL_SHARING_ENDPOINT}/get-campaign-names`;
export const SOCIAL_SHARING_SEND_PROMOCODES_FILE_ENDPOINT = `${SOCIAL_SHARING_ENDPOINT}/promocodes`;
export const SOCIAL_SHARING_VERIFY_CAMPAIGN_NAME_ENDPOINT = `${SOCIAL_SHARING_ENDPOINT}/validate-name`;
export const SOCIAL_SHARING_CALCULATE_VISITORS_ENDPOINT = `${SOCIAL_SHARING_ENDPOINT}/visitors`;

// cpa
export const CPA_ENDPOINT = `/modules/cpa`;

export const CPA_LOYALTY_ENDPOINT = `${CPA_ENDPOINT}/loyalty`;
export const CPA_FETCH_OFFERS_TO_BLOCK_ENDPOINT = `${CPA_LOYALTY_ENDPOINT}/get-offer-names`;
export const CPA_VERIFY_LOYALTY_CAMPAIGN_NAME_ENDPOINT = `${CPA_LOYALTY_ENDPOINT}/validate-name`;

export const CPA_OFFER_ENDPOINT = `${CPA_ENDPOINT}/offer`;
export const CPA_FETCH_OFFER_CAMPAIGN_NAMES_ENDPOINT = `${CPA_OFFER_ENDPOINT}/get-campaign-names`;
export const CPA_VERIFY_OFFER_CAMPAIGN_NAME_ENDPOINT = `${CPA_OFFER_ENDPOINT}/validate-name`;
export const CPA_SEND_PROMOCODES_FILE_ENDPOINT = `${CPA_ENDPOINT}/promocodes`;

export const TAGS_ENDPOINT = '/modules/tags';
export const EMAIL_CAMPAIGNS_ENDPOINT = '/modules/email/list';
export const PROMO_CODE_LIST_ENDPOINT = '/modules/promo-code-uploads/list';
export const SMS_CAMPAIGNS_ENDPOINT = '/modules/sms/list';
export const OFFER_CAMPAIGNS_ENDPOINT = '/modules/campaign/offer/list';
export const TARGET_LANGUAGES_ENDPOINT = '/modules/target/languages';
export const TAG_DEL_ENDPOINT = '/modules/tag/del';

export const GET_SHORT_URL_ENDPOINT = '/modules/short-url-global';

export const CPA_CAMPAIGNS_ENDPOINT = `${CPA_ENDPOINT}/campaigns`;
// export const CPA_STATUS_ENDPOINT = `${CPA_CAMPAIGNS_ENDPOINT}/status`;

export const CPA_ACTIVITIES_ENDPOINT = `${CPA_ENDPOINT}/statistics/msisdn`;
export const CPA_LOGS_ENDPOINT = `${CPA_ENDPOINT}/statistics/logs`;

export const CPA_SEND_SMS_CODE = `${CPA_ENDPOINT}/offer-wall/sms-tk`;


export const CPA_OFFERS_ENDPOINT = `${CPA_ENDPOINT}/offers`;
export const CPA_STATUS_ENDPOINT = `${CPA_LOYALTY_ENDPOINT}/status`;
export const CPA_STATUS_OFFER_ENDPOINT = `${CPA_OFFER_ENDPOINT}/status`;

// export const CPA_STATUS_ENDPOINT = `${CPA_CAMPAIGNS_ENDPOINT}/status`;


// field data endpoints
export const FETCH_COUNTRIES_ENDPOINT = "/modules/geolocation/country";
export const FETCH_REGIONS_ENDPOINT = "/modules/geolocation/region";
export const FETCH_CITIES_ENDPOINT = "/modules/geolocation/city";
export const FETCH_GEO_CONFIG_ENDPOINT = "/modules/geolocation/config";
export const FETCH_OPERATORS_ENDPOINT = "/modules/operators";
export const FETCH_LANGUAGES_ENDPOINT = "/modules/languages";
export const FETCH_MNOS_ENDPOINT = "/modules/mnos";
export const FETCH_TARIFFS_ENDPOINT = "/sim/tariffs/list";

//analytics
// export const FETCH_ANALYTICS_ENDPOINT = `${API_ENDPOINT}/modules/analytics/all`;
export const FETCH_ANALYTICS_ENDPOINT = `/modules/analytics/all`;
export const FETCH_ANALYTICS_SEPARATE = `/modules/analytics/separate`;
export const FETCH_RETENTION_ENDPOINT = `/modules/analytics/retention`;
export const FETCH_METRICS_FILTER = `/modules/analytics/detail/filters`;
export const FETCH_METRICS_DETAILS = `/modules/analytics/detail`;
export const FETCH_ANALYTICS_HEATMAP = `/modules/analytics/heatmap`;

//media
export const FETCH_MEDIA_ENDPOINT = `/modules/media`;

export const POST_MEDIA_ENDPOINT = `/modules/media/upload`;
export const POST_MEDIA_VIDEO_ENDPOINT = `/modules/media/upload/video`;

// customers
export const CUSTOMERS_ENDPOINT = '/cdp';
export const FETCH_CUSTOMERS_ENDPOINT = `${CUSTOMERS_ENDPOINT}/customers`;
export const FETCH_CUSTOMER_ENDPOINT = `${CUSTOMERS_ENDPOINT}/customer`;
export const FETCH_CUSTOMER_TAGS = `${CUSTOMERS_ENDPOINT}/customer/tags`;
export const RUN_CAMPAIGNS_BY_TAG = `${FETCH_CUSTOMER_ENDPOINT}/run/campaigns/by/tag`;
