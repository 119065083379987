import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import {
  getAllWidgetItems,
  getWidgetEvents,
  getWidgetEventsByElementId,
} from '../../components/OnsiteForm/selectors';
import { EDIT_ONSITE_FORM_NAME, NEW_ONSITE_FORM_NAME } from '../../constants';
import { OnsiteFormHelper } from '../../help/formSchema';

// fields
export const selectOperators = get('fieldData.operators');
export const selectTariffs = get('fieldData.tariffs');
export const selectLanguages = get('fieldData.languages');
export const selectTargetLanguages = get('fieldData.targetLanguages');
export const selectCountries = get('fieldData.countries');
export const selectRegions = get('fieldData.regions');
export const selectCities = get('fieldData.cities');
export const selectGeoConfig = get('fieldData.geoConfig');
export const selectVisitors = get('fieldData.visitors');
export const selectTags = get('fieldData.tags');
export const selectEmailCampaigns = get('fieldData.emailCampaigns');
export const selectPromoCodeList = get('fieldData.promoCodeList');
export const selectShortUrl = get('fieldData.shortUrl');
export const selectSmsCampaigns = get('fieldData.smsCampaigns');
export const selectOfferCampaigns = get('fieldData.offerCampaigns');
export const selectSmsTreeCampaigns = get('fieldData.smsTreeCampaigns');
export const selectChatBotCampaigns = get('fieldData.chatBotCampaigns');
export const selectCampaignNames = get('fieldData.campaignNames');
export const selectMnos = (state) => {
  const rawMnos = getOr({}, 'fieldData.mnos', state);
  return Object.entries(rawMnos).map(([key, value]) => ({
    ...value,
    key,
  }));
};
export const createSelectServiceByMnos = (mnosKey) => (state) => {
  let services = getOr([], `fieldData.mnos.${mnosKey}.services`, state);

  if (typeof services === 'object' && !Array.isArray(services)) {
    services = Object.entries(services).map(([key, value]) => ({
      ...value,
      key,
    }));
  }

  return services;
};
export const selectMnosMask = (mnosKey) => (state) => getOr('', `fieldData.mnos.${mnosKey}.mask`, state);
export const selectMnosMaskCodeList = (mnosKey) => (state) => getOr('', `fieldData.mnos.${mnosKey}.maskCodeList`, state);
export const selectMnosCode = (mnosKey) => (state) => getOr('', `fieldData.mnos.${mnosKey}.code`, state);
export const selectMnosPhoneLength = (mnosKey) => (state) => getOr('', `fieldData.mnos.${mnosKey}.phoneLength`, state);
export const selectMnosValidators = (mnosKey) => (state) => getOr('', `fieldData.mnos.${mnosKey}.validators`, state);
export const selectMnosPhonePlaceholder = (mnosKey) => (state) => getOr('', `fieldData.mnos.${mnosKey}.phonePlaceholder`, state);

// web push
export const selectWebPushCampaignsList = getOr([], 'webPush.list');
export const selectWebPushCampaignsListTotal = get('webPush.total');
export const selectWebPushCampaign = get('webPush.editedItem');

// email
export const selectEmailCampaignsList = getOr([], 'email.list');
export const selectEmailCampaignsListTotal = get('email.total');
export const selectEmailCampaign = get('email.editedItem');
export const selectEmailTemplates = getOr([], 'email.templateList')

// campaignOffer
export const selectOfferCampaignsList = getOr([], 'campaignOffer.list');
export const selectOfferCampaignsListTotal = get('campaignOffer.total');
export const selectOfferCampaign = get('campaignOffer.editedItem');

// sms
export const selectSmsCampaignsList = getOr([], 'sms.list');
export const selectSmsCampaignsListTotal = get('sms.total');
export const selectSmsCampaign = get('sms.editedItem');

// sms tree
export const selectSmsTreeCampaignsList = getOr([], 'smsTree.list');
export const selectSmsTreeCampaignsListTotal = get('smsTree.total');
export const selectSmsTreeCampaign = get('smsTree.editedItem');

// chat bot
export const selectChatBotCampaignsList = getOr([], 'chatBot.list');
export const selectChatBotCampaignsListTotal = get('chatBot.total');
export const selectChatBotCampaign = get('chatBot.editedItem');

// user
export const selectUserPermissions = getOr([], 'user.permissions');

// promo codes
export const selectPromoCodeUploads = getOr([], 'promoCodes.list');
export const selectPromoCodeUploadsTotal = get('promoCodes.total');
export const selectPromoCodeUpload = get('promoCodes.editedItem');

// sim numbers
export const selectSimNumberUploads = getOr([], 'simNumbers.list');
export const selectSimNumberUploadsTotal = get('simNumbers.total');
export const selectSimNumberUpload = get('simNumbers.editedItem');
export const selectSimPointsUploads = getOr([], 'simNumbers.listPoints');
export const selectSimPointsUploadsTotal = get('simNumbers.totalPoints');
export const selectSimPointsUpload = get('simNumbers.editedItemPoints');
export const selectServicesUploads = getOr([], 'simNumbers.listServices');
export const selectServicesUploadsTotal = get('simNumbers.totalServices');
export const selectServicesUpload = get('simNumbers.editedItemServices');

// onsite
export const selectOnsiteCampaignsList = getOr([], 'onsite.list');
export const selectOnsiteCampaignsListTotal = get('onsite.total');
export const selectOnsiteCampaign = get('onsite.editedItem');
export const selectTemplates = getOr([], 'onsite.templateList');
export const selectOnsiteWidgetAllElements = createSelector(
  getFormValues(EDIT_ONSITE_FORM_NAME),
  getFormValues(NEW_ONSITE_FORM_NAME),
  (editForm, newForm) => getAllWidgetItems(OnsiteFormHelper.getEditedWidget(editForm || newForm)),
);
export const selectOnsiteWidgetEvents = createSelector(selectOnsiteWidgetAllElements, (elements) => getWidgetEvents(elements));
export const selectOnsiteWidgetEventsMapByElementId = createSelector(
  selectOnsiteWidgetAllElements,
  selectOnsiteWidgetEvents,
  (elements, events) => getWidgetEventsByElementId(elements, events),
);
export const selectOnsiteWidgetEventsByElementId = (id) => createSelector(selectOnsiteWidgetEventsMapByElementId, (events) => events[id]);

// social sharing
export const selectSocialSharingCampaignsList = getOr([], 'socialSharing.list');
export const selectSocialSharingCampaignsListTotal = get('socialSharing.total');
export const selectSocialSharingCampaign = get('socialSharing.editedItem');

// cpa platform
export const selectCpaCampaignsList = getOr([], 'cpa.list');
export const selectCpaCampaignsListTotal = get('cpa.total');
export const createSelectCpaOfferCampaign = (id) => get(`cpa.editedOffers.${id}`);
export const createSelectCpaLoyaltyCampaign = (id) => get(`cpa.editedLoyalties.${id}`);
export const selectCpaMsisdnStatisticsList = getOr([], 'cpa.statistics.list');
export const selectCpaMsisdnStatisticsListTotal = get('cpa.statistics.total');
export const selectCpaMsisdnLogsList = get('cpa.logs');

// program state
export const selectActiveStep = get('programState.steppers.activeStep');
export const createSelectFormSteps = (form) => get(`programState.steppers.steps.${form}`);

// modules ids
export const selectModulesID = (modules) => modules.map((item) => item.module_id);

// analytics
export const selectAnalytics = get('analytics.analytics');
export const selectHeatmap = get('analytics.heatmap');
export const selectMetricsFilters = getOr({}, 'analytics.metricsFilters');
export const selectMetricsDetails = getOr({}, 'analytics.metricsDetails');

// customers
export const selectCustomersList = getOr([], 'customers.list');
export const selectCustomersListTotal = get('customers.total');
export const selectCustomersDetails = get('customers.details');
export const selectCampaignEventsData = get('customers.campaignEvents');
export const selectChatHistoryData = get('customers.chatHistory');
export const selectCustomersTags = getOr([], 'customers.tags');
